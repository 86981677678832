import React, {useState} from 'react'

import {images} from '../../constants'
import {AppWrap, MotionWrap} from '../../Wrapper'
import {client} from '../../client'
import './Footer.scss'

const Footer = () => {
  const [formData, setFormData] = useState({name: '', email: '', message: ''})
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const {name, email, message} = formData;
  const handleChangeInput = (e) => {
    const{name, value} = e.target;
    setFormData({...formData, [name]: value})
  }
  const handleSubmit = () => {
    setLoading(true);

    const contact = {
      _type: 'contact',
      name: formData.name,
      email: formData.email,
      message: formData.message,
    }
    client.create(contact).then(()=>{
      setLoading(false);
      setIsFormSubmitted(true);
    })
    .catch((err)=> console.log(err))
  }
  return (
    <>
      <h2 className="head-text">
        Take a coffee and chat with me
      </h2>
      <div className="app__footer-cards">
        <div className="app__footer-card">
          <img src={images.email} alt="email" />
          <a href="mailto:roshangyawali9@gmail.com" className="p-text">roshangyawali9@gmail.com</a>
        </div>
        <div className="app__footer-card">
          <img src={images.mobile} alt="mobile" />
          <a href="tell: +977 9868786197" className="p-text">+977 9868786197</a>
        </div>
      </div>

      {
        !isFormSubmitted ?
      
      <div className="app__footer-form app__flex">
        <div className="app__flex">
          <input type="text" className="p-text" placeholder='Your Name' name="name" value={name} onChange={handleChangeInput} />
        </div>
        <div className="app__flex">
          <input type="email" className="p-text" placeholder='Your Email' name="email" value={email} onChange={handleChangeInput} />
        </div>
        <div>
          <textarea className="p-text" placeholder='Your Message' value={message} name="message" onChange={handleChangeInput}></textarea>
        </div>
        <button type="button" className="p-text" onClick={handleSubmit}>
          {loading ? 'Sending' : 'Send Message'}
        </button>
      </div>
      :
      <div>
        <h3 className="head-text">Than you for getting in touch</h3>
      </div>
      }

    </>
  )
}

export default AppWrap(MotionWrap(Footer, 'app__footer'), 'contact', 'app__whitebg')